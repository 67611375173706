import angular from 'angular';
import { CSRF_COOKIE_NAME } from '../../common/const/cookies';
import homeTemplate from '../views/home.html';
import { FormController } from './applets';
import resolvers from './appResolvers';

import views_401 from '@lei-website-client/views/401.html';
import views_403 from '@lei-website-client/views/403.html';
import views_404 from '@lei-website-client/views/404.html';
import views_500 from '@lei-website-client/views/500.html';
import views_admin_adminAutoRenew from '@lei-website-client/views/admin/adminAutoRenew.html';
import views_admin_adminSubmission from '@lei-website-client/views/admin/adminSubmission.html';
import views_admin_adminUserAccount from '@lei-website-client/views/admin/adminUserAccount.html';
import views_admin_bulkUser from '@lei-website-client/views/admin/bulkUser.html';
import views_admin_faq from '@lei-website-client/views/admin/faq.html';
import views_admin_transferLei from '@lei-website-client/views/admin/transferLei.html';
import views_bulkLei from '@lei-website-client/views/bulkLei.html';
import views_cart_main from '@lei-website-client/views/cart/main.html';
import views_challengeLei from '@lei-website-client/views/challengeLei.html';
import views_docs_faq from '@lei-website-client/views/docs/faq.html';
import views_docs_IGAStatement from '@lei-website-client/views/docs/IGAStatement.html';
import views_docs_lawEnforcementStatement from '@lei-website-client/views/docs/lawEnforcementStatement.html';
import views_docs_privacyPolicy from '@lei-website-client/views/docs/privacyPolicy.html';
import views_docs_serviceCustomerAgreement from '@lei-website-client/views/docs/serviceCustomerAgreement.html';
import views_docs_termsOfService from '@lei-website-client/views/docs/termsOfService.html';
import views_docs_transferWaiver from '@lei-website-client/views/docs/transferWaiver.html';
import views_downloads from '@lei-website-client/views/downloads.html';
import views_feedback from '@lei-website-client/views/feedback.html';
import views_form_index from '@lei-website-client/views/form/index.html';
import views_leiEntry from '@lei-website-client/views/leiEntry.html';
import views_leiSelection from '@lei-website-client/views/leiSelection.html';
import views_leiViewer from '@lei-website-client/views/leiViewer.html';
import views_logout from '@lei-website-client/views/logout.html';
import views_pay from '@lei-website-client/views/pay.html';
import views_search from '@lei-website-client/views/search.html';
import views_submissions from '@lei-website-client/views/submissions.html';
import views_thank_you from '@lei-website-client/views/thankYou.html';
import views_upgrade from '@lei-website-client/views/upgrade.html';
import views_users_accountCards from '@lei-website-client/views/users/accountCards.html';
import views_users_accountEmails from '@lei-website-client/views/users/accountEmails.html';
import views_users_accountProfile from '@lei-website-client/views/users/accountProfile.html';
import views_users_forgot_username from '@lei-website-client/views/users/forgot-username.html';
import views_users_login from '@lei-website-client/views/users/login.html';
import views_users_reset_password from '@lei-website-client/views/users/reset-password.html';
import views_users_signup from '@lei-website-client/views/users/signup.html';
import views_users_unlock_account from '@lei-website-client/views/users/unlock-account.html';

const __module__ = 'appRoutes',
  app = angular.module(__module__, [resolvers]),
  { config, directive, run } = app;

config(
  /* @ngInject */ function(
    $httpProvider,
    $routeProvider,
    $locationProvider,
    $sceDelegateProvider,
    AuthResolverProvider,
    UserResolverProvider,
    LogoutResolverProvider,
    AccountEmailsResolverProvider,
    AdminRoleResolverProvider,
    UserRoleResolverProvider,
    AccessLevelResolverProvider,
    SendUnlockAccountCodeResolverProvider,
    RecaptchaResolverProvider,
    DownloadsResolverProvider,
    UserSubmissionDataViewerResolverProvider,
    ErrorMessageResolverProvider,
    InjectStripeJSResolverProvider,
    AdminRouteResolverProvider,
    AnyLeiDataViewerResolverProvider
  ) {
    $locationProvider.html5Mode(true);

    getWhens().forEach(function(w) {
      $routeProvider.when.apply($routeProvider, w);
    });

    $routeProvider.otherwise(getOtherwise());

    $httpProvider.defaults.xsrfCookieName = CSRF_COOKIE_NAME;
    // resourceUrlWhitelist = angular function;
    if (__CDN__) $sceDelegateProvider.resourceUrlWhitelist(['self', __CDN__ + '/**']);

    function getWhens() {
      var whens = [
        [
          '/',
          {
            template: homeTemplate,
            controller: 'MainController',
            title: 'Home'
          }
        ],

        [
          '/leis/entry/:type?',
          {
            template: views_leiEntry,
            controller: 'LeiEntryController',
            auth: true,
            title: 'Entry'
          }
        ],

        [
          '/leis/selection/:type?',
          {
            template: views_leiSelection,
            controller: 'LeiSelectionController',
            auth: true,
            title: 'LEI Selection'
          }
        ],

        [
          '/leis/new/:step?',
          {
            template: views_form_index,
            controller: FormController,
            controllerAs: 'formVM',
            resolve: angular.extend({}, AccountEmailsResolverProvider.resolve()),
            auth: true,
            title: 'LEI Form'
          }
        ],

        [
          '/adm1ns/user',
          {
            template: views_admin_bulkUser,
            controller: 'BulkUserController',
            auth: true,
            admin: true,
            title: 'Bulk User Creation Request'
          }
        ],

        [
          '/adm1ns/lei',
          {
            template: views_admin_transferLei,
            controller: 'AdminTransferLeiController',
            auth: true,
            admin: true,
            title: 'Bulk Transfer LEI Ownership Request'
          }
        ],

        [
          '/adm1ns/faq',
          {
            template: views_admin_faq,
            title: 'Internal FAQs',
            controller: 'faqController',
            controllerAs: 'faqVM',
            auth: true,
            admin: true
          }
        ],

        [
          '/leis/bulkRequest/:action',
          {
            template: views_bulkLei,
            controller: 'BulkLeiController',
            auth: true,
            title: 'Bulk Request'
          }
        ],

        [
          '/leis/renew/:id/:step?',
          {
            template: views_form_index,
            controller: FormController,
            controllerAs: 'formVM',
            resolve: angular.extend({}, AccountEmailsResolverProvider.resolve()),
            auth: true,
            title: 'LEI Form'
          }
        ],

        [
          '/leis/update/:id/:step?',
          {
            template: views_form_index,
            controller: FormController,
            controllerAs: 'formVM',
            resolve: angular.extend({}, AccountEmailsResolverProvider.resolve()),
            auth: true,
            title: 'LEI Form'
          }
        ],

        [
          '/search',
          {
            template: views_search,
            reloadOnSearch: false,
            title: 'Search'
          }
        ],

        [
          '/view/:id',
          {
            template: views_leiViewer,
            controller: 'LeiViewerController',
            title: 'View',
            resolve: angular.extend({}, AnyLeiDataViewerResolverProvider.resolve())
          }
        ],

        [
          '/leis/challenge/:id',
          {
            template: views_challengeLei,
            controller: 'ChallengeLeiController',
            controllerAs: 'challengeCtlr',
            resolve: angular.extend({}, AccountEmailsResolverProvider.resolve()),
            auth: true,
            title: 'Challenge'
          }
        ],

        [
          '/leis/leiChallenge/:id/:humanId',
          {
            template: views_challengeLei,
            controller: 'ViewLeiChallengeController',
            controllerAs: 'challengeCtlr',
            auth: true,
            title: 'Challenge'
          }
        ],

        [
          '/leis/transfer/:id/:step?',
          {
            template: views_form_index,
            controller: FormController,
            controllerAs: 'formVM',
            resolve: angular.extend({}, AccountEmailsResolverProvider.resolve()),
            auth: true,
            title: 'LEI Form'
          }
        ],

        [
          '/pay/:humanId/:legalName/:goBackLink?/:shoppingCart?',
          {
            template: views_pay,
            controller: 'PayController',
            auth: true,
            resolve: angular.extend(
              {},
              AccountEmailsResolverProvider.resolve(),
              InjectStripeJSResolverProvider.resolve()
            ),
            title: 'Payment'
          }
        ],

        [
          '/checkout',
          {
            template: views_pay,
            controller: 'PayController',
            auth: true,
            resolve: angular.extend(
              {},
              AccountEmailsResolverProvider.resolve(),
              InjectStripeJSResolverProvider.resolve()
            ),
            title: 'Checkout'
          }
        ],

        [
          '/users/signup',
          {
            template: views_users_signup,
            controller: 'SignUpController',
            controllerAs: 'signupVM',
            resolve: angular.extend({}, LogoutResolverProvider.resolve(), RecaptchaResolverProvider.resolve()),
            title: 'Sign Up'
          }
        ],

        [
          '/upgrade',
          {
            template: views_upgrade,
            controller: 'RefreshController',
            resolve: angular.extend({}, LogoutResolverProvider.resolve()),
            title: 'Upgrading...'
          }
        ],

        [
          '/users/logout',
          {
            template: views_logout,
            controller: 'RefreshController',
            resolve: angular.extend({}, LogoutResolverProvider.resolve()),
            title: 'Logging out...'
          }
        ],

        [
          '/users/account/profile',
          {
            template: views_users_accountProfile,
            controller: 'ProfileController',
            controllerAs: 'profileVM',
            auth: true,
            resolve: angular.extend({}, UserResolverProvider.resolve()),
            title: 'Account Profile'
          }
        ],

        [
          '/users/account/emails',
          {
            template: views_users_accountEmails,
            controller: [
              '$scope',
              'accountEmails',
              'loginEmail',
              function($scope, accountEmails, loginEmail) {
                $scope.accountEmails = accountEmails;
                $scope.loginEmail = loginEmail;
              }
            ],
            resolve: angular.extend({}, AccountEmailsResolverProvider.resolve()),
            auth: true,
            title: 'Account Emails'
          }
        ],

        [
          '/users/account/cards',
          {
            template: views_users_accountCards,
            controller: 'AccountCardsController',
            controllerAs: 'accountCardsVM',
            auth: true,
            title: 'Account Cards',
            resolve: angular.extend({}, InjectStripeJSResolverProvider.resolve())
          }
        ],

        [
          '/users/submissions/:id/:humanId',
          {
            template: views_leiViewer,
            controller: 'LeiViewerController',
            auth: true,
            title: 'Submission',
            resolve: angular.extend({}, UserSubmissionDataViewerResolverProvider.resolve())
          }
        ],

        [
          '/users/submissions',
          {
            template: views_submissions,
            auth: true,
            reloadOnSearch: false,
            title: 'My Requests'
          }
        ],

        [
          '/users/cart',
          {
            template: views_cart_main,
            controller: 'CartController',
            auth: true,
            reloadOnSearch: false,
            title: 'My Shopping Cart'
          }
        ],

        [
          '/contact/:category?',
          {
            template: views_feedback,
            controller: 'FeedbackController',
            controllerAs: 'feedbackVM',
            resolve: angular.extend({}, RecaptchaResolverProvider.resolve()),
            title: 'Contact Us'
          }
        ],

        [
          '/users/login',
          {
            template: views_users_login,
            controller: 'LoginController',
            controllerAs: 'loginVM',
            title: 'Log In'
          }
        ],

        [
          '/users/unlock-account/:username/:email',
          {
            template: views_users_unlock_account,
            controller: 'UnlockAccountController',
            controllerAs: 'unlockAccountVM',
            resolve: angular.extend({}, SendUnlockAccountCodeResolverProvider.resolve()),
            title: 'Unlock Account'
          }
        ],

        [
          '/users/reset-password',
          {
            template: views_users_reset_password,
            controller: 'ResetPasswordController',
            controllerAs: 'resetPasswordVM',
            title: 'Reset Password'
          }
        ],

        [
          '/users/forgot-username',
          {
            template: views_users_forgot_username,
            controller: 'ForgotUsernameController',
            controllerAs: 'forgotUsernameVM',
            resolve: angular.extend({}, RecaptchaResolverProvider.resolve()),
            title: 'Get Username'
          }
        ],

        [
          '/adm1ns/account',
          {
            template: views_admin_adminUserAccount,
            controller: 'AdminAccountController',
            controllerAs: 'adminAccountVM',
            title: 'Admin - Account',
            auth: true,
            admin: true,
            resolve: angular.extend({}, AccountEmailsResolverProvider.resolve()),
            internal: true,
            internalFor: 'lei.bloomberg.com'
          }
        ],

        [
          '/adm1ns/submission',
          {
            template: views_admin_adminSubmission,
            controller: 'AdminSubmissionController',
            controllerAs: 'adminSubmissionVM',
            title: 'Admin - Submissions',
            auth: true,
            admin: true,
            internal: true,
            internalFor: 'lei.bloomberg.com'
          }
        ],

        [
          '/adm1ns/autorenew',
          {
            template: views_admin_adminAutoRenew,
            controller: 'AdminAutoRenewController',
            controllerAs: 'adminAutoRenewVM',
            title: 'Admin - Auto Renew',
            auth: true,
            admin: true,
            internal: true,
            internalFor: 'lei.bloomberg.com'
          }
        ],

        [
          '/downloads',
          {
            template: views_downloads,
            controller: 'DownloadsController',
            controllerAs: 'downloadsVM',
            resolve: angular.extend({}, DownloadsResolverProvider.resolve()),
            title: 'Downloads'
          }
        ],

        [
          '/docs/terms-of-service',
          {
            template: views_docs_termsOfService,
            title: 'Terms of Service'
          }
        ],

        [
          '/docs/privacy-policy',
          {
            template: views_docs_privacyPolicy,
            title: 'Privacy Policy'
          }
        ],

        [
          '/docs/privacy-policy/law-enforcement-statement',
          {
            template: views_docs_lawEnforcementStatement,
            title: 'Law Enforcement Statement'
          }
        ],

        [
          '/docs/privacy-policy/iga-statement',
          {
            template: views_docs_IGAStatement,
            title: 'IGA Statement'
          }
        ],

        [
          '/docs/service-customer-agreement',
          {
            template: views_docs_serviceCustomerAgreement,
            title: 'Service Customer Agreement'
          }
        ],

        [
          '/docs/faq',
          {
            template: views_docs_faq,
            title: 'FAQs',
            controller: 'faqController',
            controllerAs: 'faqVM'
          }
        ],

        [
          '/docs/transfer-waiver',
          {
            template: views_docs_transferWaiver,
            title: 'Transfer Waiver'
          }
        ],

        [
          '/health',
          {
            template: [
              '<article class="container-health container-entry"><div class="container">',
              '<h1>Diagnostics</h1>',
              '<div class="container health-items">',
              '<health-item test="test" ng-repeat="(key, test) in tests"></health-item>',
              '</div>',
              '</div></article>'
            ].join(''),
            title: 'Diagnostics',
            controller: 'HealthController',
            resolve: angular.extend({}, InjectStripeJSResolverProvider.resolve())
          }
        ],

        [
          '/thank-you/:action/:trackId/:legalName/:lei',
          {
            template: views_thank_you,
            title: 'Thank You!',
            controller: 'ThankYouController',
            resolve: angular.extend({}, InjectStripeJSResolverProvider.resolve())
          }
        ],

        [
          '/401',
          {
            template: views_401,
            reloadOnSearch: false,
            title: 'Error'
          }
        ],

        [
          '/403',
          {
            template: views_403,
            reloadOnSearch: false,
            title: 'Error'
          }
        ],

        [
          '/404',
          {
            template: views_404,
            title: 'Not Found'
          }
        ],

        [
          '/500',
          {
            template: views_500,
            reloadOnSearch: false,
            title: 'Error',
            controller: 'ErrorController',
            resolve: angular.extend({}, ErrorMessageResolverProvider.resolve())
          }
        ]
      ];

      // attach auth reolve, which will raise $routeChangeError when available
      whens = whens.reduce(function(prev, w) {
        if (!w[1].internal || window.location.hostname !== w[1].internalFor) {
          w[1] = injectResolvers(w[1]);
          prev.push(w);
        }
        return prev;
      }, []);

      return whens;
    }

    function injectResolvers(whenOrOtherwiseRoute) {
      var assign = angular.extend;

      var route = assign({}, whenOrOtherwiseRoute);

      // internal only route check
      if (route.internal) {
        route.resolve = assign({}, route.resolve, AdminRouteResolverProvider.resolve());
      }

      // requires to be logged in with 2 factor code
      if (route.auth) {
        route.resolve = assign({}, route.resolve, AuthResolverProvider.resolve());
      }

      // requires "admin" role
      if (route.admin) {
        route.resolve = assign({}, route.resolve, AdminRoleResolverProvider.resolve());
      }

      // requires "user" role
      if (route.user) {
        route.resolve = assign({}, route.resolve, UserRoleResolverProvider.resolve());
      }

      // always request access level to populate correct navbar
      route.resolve = assign({}, route.resolve, AccessLevelResolverProvider.resolve());

      return route;
    }

    function getOtherwise() {
      // NOTE: Using regEx instead of A element, since the
      // handled UTM paths are extremely simple
      var reTrim = /(?:^\/m\/)|(?:[/]+$)/g;
      var reDirty = /[#?][^/=&;]+(?:[=;&]|$)/;
      var reSplit = /[/]{1,}/g;
      return injectResolvers({
        redirectTo: function(dummy, path) {
          if (!/(?:^\/m\/)|(?:[/]+$)/.test(path)) {
            return '/404';
          }

          if (reDirty.test(path) || (path = path.replace(reTrim, '').split(reSplit)).length > 2) {
            return '/404';
          }
          // take off the first segment and try redirect
          //
          // NOTE: while I'd love to allow just one retry
          // and send to 404 on a second failure, angular
          // has a problem preserving/adding params:
          // https://github.com/angular/angular/issues/12664
          path[0] = '';
          return path.join('/') || '/';
        }
      });
    }
  }
);

directive('leiTitle', [
  '$rootScope',
  function($rootScope) {
    return {
      restrict: 'A',
      link: function(scope, element, attrs) {
        function onChangeTitle(event, current) {
          console.log('[leiTitle]', current.title);
          element.html(current.title + ' | Bloomberg LEI');
        }
        $rootScope.$on('$routeChangeSuccess', onChangeTitle);
        $rootScope.$on('onChangeTitle', onChangeTitle);
      }
    };
  }
]);

run([
  '$rootScope',
  '$anchorScroll',
  'Redirect',
  '$window',
  function($rootScope, $anchorScroll, Redirect, $window) {
    $anchorScroll.yOffset = 180; // always scroll by 180 extra pixels (nav bar)

    $rootScope.$on('$locationChangeStart', function(event, nextUrl) {
      $window.ga && $window.ga('set', 'page', nextUrl);
      $window.ga && $window.ga('send', 'pageview');

      // GA4 experiment
      const gtag = $window.gtag || function() {};
      gtag('event', 'page_view', {
        page_location: nextUrl
      });
    });

    $rootScope.$on('$routeChangeError', function(event, current, prev, rejection) {
      var response = rejection;
      var statusCode = response.status;

      if (statusCode && statusCode >= 200 && statusCode <= 500) {
        if (statusCode === 401) {
          Redirect.remember();
          Redirect.loginAfter401();
        } else {
          Redirect.status(statusCode, rejection, current.url);
        }
      }

      throw rejection;
    });
  }
]);

export { __module__ as default };
