import angular from 'angular';
import { controller } from './app';
import {
  ACTION as LEI_ACTION,
  MAX_BULK_EXPORT,
  MAX_CLIENT_BULK_EXPORT,
  TRANSFERABLES
} from '../../../common/const/lei-products';
import ISO_COUNTRY_CODES from '../../../common/const/iso-country-codes-alpha2';

controller('LeiGridController', [
  '$rootScope',
  '$route',
  '$location',
  '$scope',
  '$timeout',
  '$q',
  'IssuedLeiPaginationService',
  'IssuedLeiColumnDefs',
  'RequestedLeiPaginationService',
  'RequestedLeiColumnDefs',
  'AutoRenewPaginationService',
  'AutoRenewLEIColumnDefs',
  'GleifLEIsPaginationService',
  'GleifLeisColumnDefs',
  'BloombergLEIsColumnDefs',
  'BloombergLEIsPaginationService',
  'DraftPaginationService',
  'DraftColumnDefs',
  'AlertMessenger',
  'uiGridConstants',
  function(
    $rootScope,
    $route,
    $location,
    $scope,
    $timeout,
    $q,
    IssuedLeiPaginationService,
    IssuedLeiColumnDefs,
    RequestedLeiPaginationService,
    RequestedLeiColumnDefs,
    AutoRenewPaginationService,
    AutoRenewColumnDefs,
    GleifLEIsPaginationService,
    GleifLeisColumnDefs,
    BloombergLEIsColumnDefs,
    BloombergLEIsPaginationService,
    DraftPaginationService,
    DraftColumnDefs,
    AlertMessenger,
    uiGridConstants
  ) {
    var vm = this;
    vm.props = {
      secLabel: '',
      submissions: [],
      gridOptions: {},
      filterText: null,
      filterSelect: {},
      filter: enhanceNamedFilter([]),
      usedFilter: {},
      hideAddFilter: false
    };
    vm.actions = {
      refreshData: refreshData,
      unhideColumns: unhideColumns,
      nudgeFilter: nudgeFilter,
      clearFilter: clearFilter,
      nudgeFilterOnCategoryChange,
      addFilter: addFilter,
      availableFilters: availableFilters,
      getPlaceHolder: getPlaceHolder
    };
    // store the ids for export
    $scope.exportIds = $scope.exportMyLeis = {};
    // handles back, forward browser button clicks
    var once = true;

    vm.props.filterSelect = {
      registeredCountry: ISO_COUNTRY_CODES.reduce((acc, e) => {
        acc[e['alpha-2']] = e.name;
        return acc;
      }, {})
    };

    $rootScope.$on('$routeUpdate', function() {
      var st = $location.search();
      if (st) {
        st = st['ts'];
        if (st && !isNaN(st)) {
          if (once) {
            st = parseInt(st);
            if (st && st != stamp()) {
              once = false;
              $route.reload();
            }
          }
        }
      }
    });

    var infiniteScrollPageSize = 50;

    $scope.maxBulkExportCount = MAX_BULK_EXPORT;
    $scope.maxClientBulkExportCount = MAX_CLIENT_BULK_EXPORT;
    $scope.submissionType = undefined;

    var sortDef;

    var ctxOptions = {
      LEIsPaginationService: IssuedLeiPaginationService,
      LEIsColumnDefs: IssuedLeiColumnDefs,
      LEIsSecLabel: 'LEIs',

      SubmissionsPaginationService: RequestedLeiPaginationService,
      SubmissionsColumnDefs: RequestedLeiColumnDefs,
      SubmissionsSecLabel: 'Submissions',

      'Additional LEIsPaginationService': GleifLEIsPaginationService,
      'Additional LEIsColumnDefs': GleifLeisColumnDefs,

      'Auto RenewPaginationService': AutoRenewPaginationService,
      'Auto RenewColumnDefs': AutoRenewColumnDefs,
      'Auto RenewSecLabel': 'Auto Renew',

      'Ownership TransferSecLabel': 'Ownership Transfer',
      'Ownership TransferColumnDefs': [],
      'Ownership TransferPaginationService': {
        getPage: function() {
          return $q.resolve({ data: [] });
        }
      },

      'Bloomberg LEIsColumnDefs': BloombergLEIsColumnDefs,
      'Bloomberg LEIsPaginationService': BloombergLEIsPaginationService,

      SearchSecLabel: 'Search LEIs',

      DraftsPaginationService: DraftPaginationService,
      DraftsColumnDefs: DraftColumnDefs,
      DraftsSecLabel: 'Drafts'
    };

    $scope.loading = false;
    $scope.action = {
      hasValidAction: function(data) {
        if ($scope.const.index.searchMode[$scope.state.searchMode] === 'Additional LEIs') {
          return TRANSFERABLES.indexOf(data.registrationStatus) > -1;
        } else {
          return true;
        }
      },
      getUri: function(data) {
        return '/leis/' + encodeURIComponent(this.getName(data).toLowerCase()) + '/' + encodeURIComponent(data.key);
      },
      getName: function(data) {
        var action = LEI_ACTION.CHALLENGE;
        if ($scope.const.index.searchMode[$scope.state.searchMode] === 'Additional LEIs') {
          action = LEI_ACTION.TRANSFER;
        }
        return action;
      },
      getLeiLink: function(data) {
        if ($scope.submissionType) {
          return '/leis/' + $scope.submissionType + '/' + encodeURIComponent(data.key);
        } else {
          if ($scope.const.index.searchMode[$scope.state.searchMode] === 'Additional LEIs') {
            return '/gleifs/view/' + encodeURIComponent(data.key);
          } else {
            return '/leis/view/' + encodeURIComponent(data.key);
          }
        }
      },
      redirect: function(data) {
        if (!$scope.submissionType) {
          if ($scope.const.index.searchMode[$scope.state.searchMode] === 'Additional LEIs') {
            window.location.href = `/gleifs/view/${data.key}`;
          } else {
            window.location.href = `/leis/view/${data.key}`;
          }
        }
      }
    };

    var apiRegisterDeffered = $q.defer();
    $scope.gridOptions = {
      enableHorizontalScrollbar: 2,
      enableVerticalScrollbar: 2,
      enableColumnResizing: 1,
      //
      infiniteScrollRowsFromEnd: 10,
      infiniteScrollUp: true,
      infiniteScrollDown: true,
      infiniteScrollPageSize: infiniteScrollPageSize,
      useExternalSorting: true,

      //
      columnDefs: [],
      //
      data: 'data',
      onRegisterApi: function(gridApi) {
        gridApi.infiniteScroll.on.needLoadMoreData($scope, getDataDown);
        gridApi.infiniteScroll.on.needLoadMoreDataTop($scope, getDataUp);
        gridApi.core.on.sortChanged($scope, applySort);
        gridApi.core.on.columnVisibilityChanged($scope, trackVisibility);
        $scope.gridApi = gridApi;
        apiRegisterDeffered.resolve();
      },
      sortInfo: { fields: ['submissionTime'], directions: ['desc'] }
      //
      // data: 'vm.props.submissions'
    };

    $scope.const = {
      searchMode: {
        Search: {
          'Bloomberg LEIs': 1,
          'Additional LEIs': 2
        },
        Mixed: {
          Submissions: 3,
          LEIs: 4,
          Drafts: 5,
          'Auto Renew': 6,
          'Ownership Transfer': 7
        },
        LeiEntry: {
          'Additional LEIs': 2,
          LEIs: 4
        }
      }
    };

    function mapToMixedSearchMode(value) {
      return $scope.const.searchMode.Mixed[value];
    }

    $scope.noSearchModes = ['Auto Renew', 'Drafts', 'Ownership Transfer'].map(mapToMixedSearchMode);
    $scope.noBadgeModes = ['Submissions', 'Ownership Transfer'].map(mapToMixedSearchMode);
    $scope.noGridModes = ['Ownership Transfer'].map(mapToMixedSearchMode);
    $scope.filterMode = function(list) {
      return list.indexOf($scope.state.searchMode) > -1;
    };

    $scope.hideKeywordSearch = function() {
      return (
        $scope.noSearchModes.indexOf($scope.state.searchMode) > -1 &&
        $scope.state.searchMode != $scope.const.searchMode['Auto Renew']
      );
    };

    var hiddenColumnsStorageKey = 'hiddenSearchColumns';
    var hiddenColumns = loadHiddenColumns();
    function parseJSON(str) {
      try {
        return JSON.parse(str);
      } catch (e) {
        console.log('Failed parsing an object with: ', e);
        return str;
      }
    }
    function loadHiddenColumns() {
      try {
        return parseJSON(window.sessionStorage.getItem(hiddenColumnsStorageKey)) || {};
      } catch (err) {
        console.log(' << hidden column state load has failed.');
        return {};
      }
    }
    function saveHiddenColumns() {
      try {
        window.sessionStorage.setItem(hiddenColumnsStorageKey, JSON.stringify(hiddenColumns));
      } catch (e) {
        console.log('Failed saving hidden column state with: ', e);
      }
    }

    $scope.state = {
      searchMode: undefined,
      hasHiddenColumns: false
    };

    $scope.handleSearchModeUpdate = function(mode) {
      // clear out the export ids between tabs
      $scope.exportIds = $scope.exportMyLeis = {};

      var changed = $scope.state.searchMode !== mode;
      updateSearchMode(mode);
      if (changed) {
        // reflect the new mode in the url
        var arg = getQueryParamsFromLocation();
        arg[0] = mode;
        updateLocation(arg);
        // reload taking the sortDefs, query, etc into consideration
        // return $scope.reset( true );
        vm.props.filter = enhanceNamedFilter([]);
        nudgeFilter();
      } else {
        return $q.resolve();
      }
    };

    function markFiltersAsUsed() {
      vm.props.usedFilter = vm.props.filter.reduce(function(acc, flt) {
        if (!flt.field) return acc;
        acc[flt.field] = true;
        return acc;
      }, {});
    }

    function serializeNamedFilter() {
      var flt = nudgeNamedFilter(this.slice(), true);
      return JSON.stringify(flt);
    }

    function stripNamedFilter(filter) {
      var flt = nudgeNamedFilter((filter || []).slice());
      flt.join = serializeNamedFilter;
      return flt;
    }

    function enhanceNamedFilter(filter, add) {
      filter.join = serializeNamedFilter;
      return filter;
    }

    function addFilter() {
      if (
        vm.props.filter.some(function(flt) {
          return !flt.field;
        })
      )
        return;
      vm.props.filter.push({ value: '' });
    }

    function availableFilters(cur) {
      var col = (vm.props.columnDefs || []).filter(function(flt) {
        return (
          flt && flt.field && flt.field.slice(0, 1) !== '_' && (!vm.props.usedFilter[flt.field] || flt.field === cur)
        );
      });
      vm.props.hideAddFilter = !Array.isArray(col) || col.length <= 1;
      return col;
    }

    function clearFilter(i) {
      var flt;
      if (i != null) flt = vm.props.filter.splice(i, 1)[0];
      else flt = vm.props.filter.splice(0, vm.props.filter.length)[0];
      nudgeFilter(!flt || !flt.value || i == null);
    }

    function nudgeNamedFilter(flt, clean) {
      var columnDefsMap = vm.props && vm.props.columnDefs && vm.props.columnDefs.__map;
      for (var it, k, v, l = flt.length - 1; l >= 0; l--) {
        it = flt[l];
        if (it && it.field) {
          const possibleOptions = vm.props.filterSelect[it.field];
          if (possibleOptions) {
            if (!Object.prototype.hasOwnProperty.call(possibleOptions, flt[l].value)) {
              flt[l].value = null;
            }
          }
        }
        if (!it || !it.field || it.value === '' || (columnDefsMap && !columnDefsMap[it.field])) {
          flt.splice(l, 1);
          continue;
        }

        v = (it.value && !isNaN(it.value) && Math.floor(Number(it.value))) || undefined;
        if (v != undefined) {
          it.numValue = v;
        } else {
          delete it.numValue;
        }

        if (clean) {
          for (k in it) {
            if (k.slice(0, 1) == '$') {
              delete it[k];
            }
          }
        }
      }
      return flt;
    }

    function index(obj) {
      var r = {};
      for (var k in obj) {
        r[obj[k]] = k;
      }
      return r;
    }

    function stamp() {
      return Math.floor(new Date().valueOf() / 666);
    }

    function contextItems() {
      var ctxItems = {
        paginationService: null,
        columnDefs: $scope.gridOptions,
        secLabel: vm.props
      };
      var searchModeName = $scope.const.index.searchMode[$scope.state.searchMode];

      ctxItems.paginationService = ctxOptions[searchModeName + 'PaginationService'];
      $scope.gridOptions.columnDefs = ctxOptions[searchModeName + 'ColumnDefs'];
      if ($scope.submissionType) {
        $scope.gridOptions.columnDefs = [
          ...$scope.gridOptions.columnDefs.filter(col => !['_action', '_actions', '_bulk'].includes(col.field))
        ];
      }
      vm.props.secLabel = ctxOptions[searchModeName + 'SecLabel'];

      var monitored = ctxItems.paginationService._getPage;
      if (!monitored) {
        ctxItems.paginationService._getPage = ctxItems.paginationService.getPage;
        ctxItems.paginationService.getPage = function() {
          var arg = [arguments[0]].concat(Array.prototype.slice.call(arguments, 3));
          updateLocation(arg);
          return ctxItems.paginationService._getPage.apply(null, arguments);
        };
      }
      var def = ctxItems.columnDefs.columnDefs;
      if (def.__mark == undefined) {
        def.__mark = stamp();
      }
      if (!vm.props.columnDefs || def.__mark != vm.props.columnDefs.__mark) {
        (vm.props.columnDefs = def.slice()).__mark = def.__mark;
        def = vm.props.columnDefs;
        var map = {},
          remove = [];
        for (var i = 0, l = def.length, f; i < l; i++) {
          if ((f = def[i].field)) {
            if (map[f] || f.slice(0, 1) == '_') {
              remove.push(i);
            } else {
              map[f] = f;
            }
          }
        }
        def.__map = map;
        while ((map = remove.pop())) {
          def.splice(map, 1);
        }
        def.unshift({ field: '_', displayName: '' });
      }
      return ctxItems;
    }

    function getValidSearchMode(params) {
      return parseInt(
        Array.isArray(params) && params[0] in $scope.const.index.searchMode
          ? params[0]
          : Object.values($scope.const.searchMode)[0]
      );
    }

    $scope.init = function(context, action) {
      if (!(context in $scope.const.searchMode)) {
        context = 'Mixed';
      }

      $scope.const.searchMode = $scope.const.searchMode[context];
      if (!$scope.const.index) {
        $scope.const.index = {};
      }
      $scope.const.index.searchMode = index($scope.const.searchMode);
      $scope.submissionType = action;

      var params = getQueryParamsFromLocation();
      var loc = getValidSearchMode(params);

      if (context === 'LeiEntry') {
        var key = action === 'transfer' ? 'Additional LEIs' : 'LEIs';
        loc = $scope.const.searchMode[key];
      }
      $scope.state.searchMode = loc;

      updateSearchMode(loc);
      load();
    };

    function getInitialData(useUrlState) {
      $scope.data = [];
      $scope.dataPageSize = [];
      $scope.firstPage = 0;
      $scope.lastPage = 0;
      $scope.dataSize = '~';
      return getDataDown(useUrlState);
    }

    function hasMoreDataDown() {
      return !($scope.dataPageSize.slice(-1)[0] < infiniteScrollPageSize);
    }

    function hasMoreDataUp() {
      return $scope.firstPage > 0;
    }

    var cachedSortOrder;
    function updateLocation(arg) {
      if (!angular.isArray(arg)) {
        return;
      }
      for (var i = 0, l = arg.length; i < l; i++) {
        arg[i] = arg[i] || '';
        if (arg[i].join) {
          arg[i] = arg[i].join(',');
        }
      }
      var params = getQueryParamsFromLocation();
      if (params.join('\t') !== arg.join('\t')) {
        const searchModeName = $scope.const.index.searchMode[$scope.state.searchMode] + 'ColumnDefs';
        const validSortDef = ctxOptions[searchModeName].filter(field => field.enableSorting !== false);

        if (arg[2] && !params[2]) {
          cachedSortOrder = params[1];
          if (Array.isArray(cachedSortOrder)) {
            cachedSortOrder = cachedSortOrder.join(',');
          }
          arg[1] = '';
        } else if (!arg[2] && params[2]) {
          if (cachedSortOrder) {
            const isValid = validSortDef.some(field => cachedSortOrder.includes(field.field));
            // apply the pre query sort order
            if (isValid) {
              arg[1] = cachedSortOrder;
              cachedSortOrder = undefined;
            }
          }
        }

        const defaultSortDef = ctxOptions[searchModeName].filter(field => field.sort);

        if (arg[1]) {
          const isValid = validSortDef.some(field => arg[1].includes(field.field));

          if (!isValid && defaultSortDef[0])
            arg[1] =
              defaultSortDef[0].sort.direction == 'desc' ? '-' + defaultSortDef[0].field : defaultSortDef[0].field;
        }

        $location.search({ '.': arg, ts: stamp() });
        $location.replace();
      }
    }

    function getQueryParamsFromLocation() {
      var q = angular.copy(($location.search() || {})['.'] || []); // Remove side effects;
      if (!angular.isArray(q)) {
        q = [q];
      }
      if (q[4] && (q[4] = parseJSON(q[4]))) {
        q[4] = enhanceNamedFilter(q[4]);
      }
      return q;
    }

    function unhideColumns() {
      var first = true;
      var c = $scope.gridOptions.columnDefs;
      // NOTE: At this stage, the gridApi.grid.columns array is empty and no
      // API will operate normally on it. Instead, adjusting the grid options,
      // since they will kick in once the data loads.
      var gc = ($scope.gridApi.grid || {}).columns || [];
      for (var i = 0, l = c.length; i < l; i++) {
        gc[i] && gc[i].showColumn();
        first = false;
      }
      delete hiddenColumns[$scope.state.searchMode];
      saveHiddenColumns();
      updateSearchMode($scope.state.searchMode);
      if (!first) {
        $scope.gridApi.core.refresh();
        $timeout(function() {
          return $scope.gridApi.grid.notifyDataChange(uiGridConstants.dataChange.OPTIONS);
        });
      }
      return !first;
    }

    function replaceSort(newSort, forced) {
      var first = true;
      var c = $scope.gridOptions.columnDefs;
      sortDef = (newSort || '').split(',');
      // NOTE: At this stage, the gridApi.grid.columns array is empty and no
      // API will operate normally on it. Instead, adjusting the grid options,
      // since they will kick in once the data loads.
      var j, k, it, gc, dir;
      for (var i = 0, l = sortDef.length; i < l; i++) {
        it = sortDef[i].replace(/^[-+]/, '');
        for (j = 0, k = c.length; j < k; j++) {
          if (c[j].field == it) {
            dir = sortDef[i].slice(0, 1);
            gc = (($scope.gridApi.grid || {}).columns || [])[j];
            if (gc) {
              $scope.gridApi.grid.sortColumn(gc, dir == '-' ? uiGridConstants.DESC : uiGridConstants.ASC, !first);
            }
            c[j]._sort = { direction: dir == '-' ? 'desc' : 'asc' };
            first = false;
            break;
          }
        }
      }
      // takes care of preserving default sort, if no explicit sorting has been provided
      // further, it gives existing, explicit sort precedence, removing sorting defaults.
      if (forced || !first) {
        for (j = 0, k = c.length; j < k; j++) {
          delete c[j].sort;
          gc = (($scope.gridApi.grid || {}).columns || [])[j];
          if ((it = c[j]._sort)) {
            delete c[j]._sort;
            c[j].sort = it;
          } else if (gc) {
            gc.sort = {};
          }
        }
        $scope.gridApi.core.refresh();
        $timeout(function() {
          return $scope.gridApi.grid.notifyDataChange(uiGridConstants.dataChange.OPTIONS);
        });
      }
      return !first;
    }

    function getDataDown(useUrlState) {
      var page = $scope.lastPage;
      var filter = vm.props.filterText || undefined;
      var namedFilter = stripNamedFilter(vm.props.filter);
      var numFilter = (filter && !isNaN(filter) && Math.floor(Number(filter))) || undefined;
      var params = getQueryParamsFromLocation();
      if (useUrlState) {
        const searchMode = getValidSearchMode(params);
        if (searchMode && !isNaN(searchMode)) {
          if (!$scope.state) {
            $scope.state = {};
          }
          updateSearchMode(searchMode);
          $scope.state.searchMode = searchMode;
        }
        if (params[1]) {
          replaceSort(params[1]);
        }
        if (params[2]) {
          vm.props.filterText = filter = params[2];
        }
        if (params[3]) {
          numFilter = params[3];
        }
        if (params[4]) {
          vm.props.filter = namedFilter = params[4];
          nudgeFilter(true);
        }
      } else {
        if (filter && !params[2]) {
          replaceSort(undefined, true);
        } else if (!filter && params[2]) {
          if (cachedSortOrder) {
            // apply the pre query sort order
            replaceSort(cachedSortOrder, true);
          }
        }
      }
      $scope.loading = true;
      const searchModeName = $scope.const.index.searchMode[$scope.state.searchMode] + 'ColumnDefs';
      const allowedSortDefs = ctxOptions[searchModeName]
        .filter(field => field.enableSorting === undefined || field.enableSorting !== false)
        .reduce((acc, cur) => {
          return acc.concat([cur?.field, `-${cur?.field}`]);
        }, []);
      return contextItems()
        .paginationService.getPage(
          ($scope.state || {}).searchMode,
          page * infiniteScrollPageSize,
          infiniteScrollPageSize,
          // Ensure sortDef has valid sortDefs
          (sortDef || []).filter(ele => allowedSortDefs.includes(ele)),
          filter,
          numFilter,
          namedFilter
        )
        .then(function(data) {
          // Check if current mode matches the searchMode
          const currentSearchMode = getValidSearchMode(params);
          if (Number(currentSearchMode) === Number(($scope.state || {}).searchMode)) {
            $scope.loading = false;
            $scope.lastPage = ++page;
            $scope.dataPageSize.push(data.data.length || 0);
            $scope.data = $scope.data.concat(data.data);
            $scope.ids = $scope.data.slice(0, infiniteScrollPageSize).map(function(obj, index) {
              return obj.key;
            });
            $scope.dataSize = data.totalResults || '?';
            $scope.gridApi.infiniteScroll.dataLoaded(hasMoreDataUp(), hasMoreDataDown()).then(function() {
              return checkDataLength('up');
            });
            return $q.resolve();
          } else {
            const error =
              'Mismatched search mode. ' +
              'Too many requests are made within a short period of time. ' +
              `CurrentSearchMode=${currentSearchMode}, ` +
              `SearchModeInState=${Number(($scope.state || {}).searchMode)}`;
            return $q.reject(error);
          }
        })
        .catch(function(error) {
          $scope.dataSize = '?';
          $scope.loading = false;
          console.log('Error loading data, down, ', error);
          return $scope.gridApi.infiniteScroll.dataLoaded(false, false);
        });
    }

    function getDataUp() {
      var page = $scope.firstPage;
      var filter = vm.props.filterText || undefined;
      var namedFilter = stripNamedFilter(vm.props.filter);
      var numFilter = (filter && !isNaN(filter) && Math.floor(Number(filter))) || undefined;
      $scope.loading = true;
      return contextItems()
        .paginationService.getPage(
          ($scope.state || {}).searchMode,
          --page * infiniteScrollPageSize,
          infiniteScrollPageSize,
          sortDef,
          filter,
          numFilter,
          namedFilter
        )
        .then(function(data) {
          $scope.loading = false;
          $scope.firstPage = page;
          $scope.dataPageSize.unshift(data.data.length || 0);
          $scope.data = data.data.concat($scope.data);
          $scope.ids = $scope.data.slice(0, infiniteScrollPageSize).map(function(obj, index) {
            return obj.key;
          });
          $scope.dataSize = data.totalResults || '?';
          $scope.gridApi.infiniteScroll.dataLoaded(hasMoreDataUp(), hasMoreDataDown()).then(function() {
            return checkDataLength('down');
          });
          return $q.resolve();
        })
        .catch(function(error) {
          $scope.dataSize = '?';
          $scope.loading = false;
          return $scope.gridApi.infiniteScroll.dataLoaded(false, false);
        });
    }

    function checkDataLength(discardDirection) {
      // work out whether we need to discard a page, if so discard from the direction passed in
      if ($scope.lastPage - $scope.firstPage > 3) {
        // we want to remove a page
        $scope.gridApi.infiniteScroll.saveScrollPercentage();
        if (discardDirection === 'up') {
          $scope.data = $scope.data.slice(infiniteScrollPageSize);
          $scope.firstPage++;
          $scope.dataPageSize.shift();
          $timeout(function() {
            // wait for grid to ingest data changes
            return $scope.gridApi.infiniteScroll.dataRemovedTop(hasMoreDataUp(), hasMoreDataDown());
          });
        } else {
          $scope.data = $scope.data.slice(0, 3 * infiniteScrollPageSize);
          $scope.lastPage--;
          $scope.dataPageSize.pop();
          $timeout(function() {
            // wait for grid to ingest data changes
            return $scope.gridApi.infiniteScroll.dataRemovedBottom(hasMoreDataUp(), hasMoreDataDown());
          });
        }
      }

      console.log(
        'checkDataLength ',
        discardDirection,
        $scope.firstPage,
        ' ',
        $scope.lastPage,
        ' -> ',
        $scope.data.length,
        ', ',
        $scope.dataPageSize
      );

      return $q.resolve();
    }

    function updateSearchMode(newMode) {
      $scope.state.searchMode = newMode;
      $scope.state.hasHiddenColumns = !!hiddenColumns[newMode];
      return newMode;
    }

    function updateSortDef(sortColumns) {
      var sort = [];
      for (var i = 0, l = sortColumns.length, it, dir; i < l; i++) {
        if ((it = sortColumns[i]) && (dir = it.sort) && (dir = dir.direction) && (it = it.field)) {
          sort.push((dir === 'desc' ? '-' : '') + it);
        }
      }
      if (sort.length === 0) {
        sort = undefined;
      }
      if ((sort || []).join('\t') !== (sortDef || []).join('\t')) {
        sortDef = sort;
        return true;
      }
      return false;
    }

    function _isNewSort(column) {
      const sort = `${(column?.sort?.direction || 'asc') === 'desc' ? '-' : ''}${column.field}`;
      return (sortDef || []).indexOf(sort) < 0;
    }

    function applySort(grid, sortColumns) {
      // Disable sorting by multiple fields
      // https://jira.prod.bloomberg.com/browse/ENG4LEI-1077?focusedCommentId=4359279&page=com.atlassian.jira.plugin.system.issuetabpanels:comment-tabpanel#comment-4359279
      // for some reason suppressMultiSort never works
      const newSort = sortColumns.filter(_isNewSort);
      if (newSort.length > 0) {
        // unsort unneeded sort
        const oldSort = sortColumns.filter(column => !_isNewSort(column));
        oldSort.forEach(sort => {
          sort.unsort();
        });
      }
      const sort = newSort.length > 0 ? newSort : sortColumns;
      while (sort.length > 1) {
        sort[0].unsort();
        sort.shift();
      }
      return updateSortDef(sort) ? $scope.reset() : $q.resolve();
    }

    function trackVisibility(column) {
      if (!hiddenColumns[$scope.state.searchMode]) {
        hiddenColumns[$scope.state.searchMode] = {};
      }
      hiddenColumns[$scope.state.searchMode][column.colDef.field] = true;
      saveHiddenColumns();
      updateSearchMode($scope.state.searchMode);
    }

    $scope.reset = function(useUrlState) {
      AlertMessenger.reset();

      $scope.firstPage = 0;
      $scope.lastPage = 1;

      // turn off the infinite scroll handling up and down - hopefully this won't be needed after @swalters scrolling changes
      $scope.gridApi.infiniteScroll.setScrollDirections(false, false);
      $scope.data = [];

      return getInitialData(useUrlState).then(function() {
        return $timeout(function() {
          // timeout needed to allow digest cycle to complete,and grid to finish ingesting the data
          return $scope.gridApi.infiniteScroll.resetScroll($scope.firstPage > 0, $scope.lastPage < 4);
        });
      });
    };

    // init submission history screen
    function load() {
      // reset alert message
      AlertMessenger.reset();

      $q.when()
        .then(function() {
          return apiRegisterDeffered.promise;
        })
        .then(function() {
          // ensure the columns are loaded and available
          contextItems();
          // Check for default sorting and sort
          sortDef = (getQueryParamsFromLocation()?.[1] || '').split(',');
          updateSortDef($scope.gridOptions.columnDefs);
        })
        .then(function() {
          var useUrl = ($location.absUrl() || '').indexOf('?') > -1;
          return getInitialData(useUrl).then(function() {
            return $timeout(function() {
              // timeout needed to allow digest cycle to complete,and grid to finish ingesting the data
              // you need to call resetData once you've loaded your data if you want to enable scroll up,
              // it adjusts the scroll position down one pixel so that we can generate scroll up events
              return $scope.gridApi.infiniteScroll.resetScroll($scope.firstPage > 0, $scope.lastPage < 4);
            });
          });
        });
    }

    var busy = 0;
    function refreshData() {
      $scope.exportIds = $scope.exportMyLeis = {};
      if (!busy) {
        busy = 1;
        return $scope
          .reset()
          .then(function() {
            if (busy === 2) {
              busy = 0;
              return $timeout(function() {
                return refreshData().then(function() {
                  return $q.resolve();
                });
              });
            } else {
              busy = 0;
            }
          })
          .catch(function() {
            return $q.resolve();
          });
      } else {
        busy = 2;
      }
      return $q.resolve();
    }

    function getStrippedNamedQueryParams() {
      var arg = getQueryParamsFromLocation();
      arg[4] = stripNamedFilter(vm.props.filter);

      return arg;
    }

    function setStrippedNamedQueryParams(arg, skipRefresh) {
      updateLocation(arg);
      markFiltersAsUsed();
      if (!skipRefresh) refreshData();
    }

    function nudgeFilter(skipRefresh) {
      var arg = getStrippedNamedQueryParams();
      setStrippedNamedQueryParams(arg, skipRefresh);
    }

    function nudgeFilterOnCategoryChange(field) {
      if (field === null) return;

      var arg = getStrippedNamedQueryParams();

      const idx = arg[4].findIndex(flt => flt.field === field);
      if (idx != -1) {
        arg[4][idx].value = '';
      }
      setStrippedNamedQueryParams(arg);
    }

    function getPlaceHolder(label) {
      var now = new Date();
      return (label || '').slice(-4) in { Date: 1, Time: 1 }
        ? 'Date: ' +
            [
              String(now.getFullYear()),
              [String(now.getFullYear()), ('00' + String(now.getMonth() + 1)).slice(-2)].join('-'),
              [String(now.getFullYear()), ('00' + String(now.getMonth() + 1)).slice(-2), '01'].join('-')
            ].join(', ') +
            ''
        : 'contains...';
    }
  }
]);
